import React, {useEffect, useState} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import NavBar from '../components/NavBar'
import imageCheckmark from '../images/ceoh-checkmark.png'
import imageLogoceoh from '../images/logo-ceoh.png'


export default function CEOHResourceLibrary() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


	return (
    <main>
      <title>CE-OH! Resource Library</title>

			<NavBar global={global} dispatch={dispatch} />
			<div className='page-heading'>
				<div className='container'>

						<h1>
						CE-OH! Resource Library
						</h1>

				</div>
			</div>
			<div className='container'>

				<div className='row gx-5'>
					<div className="col">
								<img alt="C E O logo" src={imageLogoceoh} />
					</div>
					<div className="col">
					<h2>Business and Entrepreneurship Training</h2>
								<p>CE-Oh! is a virtual business and entrepreneurship training program that primes participants for success. Whether a person wants to start their own business, enter the workforce, become an apprentice, or take college courses, CE-Oh! offer <strong>timeless skills for the modern worker.</strong></p>
								<p>This blended learning curriculum is designed for <strong>unemployed and underemployed individuals</strong> looking for ways to build their portfolio of skills and gain economic traction.</p>
								<p>CE-Oh! is an eight-week program that combines online learning with live, facilitator-led sessions. Participants team up and build their own simulated business with the help of mentors. Along the way, they hear from guest experts who educate and motivate.</p>
					</div>
				</div>
				<hr/>
				<div className="row gx-5">

					<div className="col">
					<h2>START SMALL.</h2>
						<h2>DREAM BIG.</h2>
						<h2><em>ACT NOW.</em></h2>
						<p>CE-Oh! is a business and entrepreneurship course that helps people monetize their passion, skills, and talent. It delivers powerful concepts with plain words, hard facts, and engaging stories. It is practical—and inspiring.</p>
						<p>This training package is modular and customizable based on the needs of participants. With a range of training approaches, CE-Oh! can include a focus on self-paced learning, collaborative skill-building exercises, or community engagement events.</p>
					</div>

					<div className="col">
						<h2>Participants who complete the program:</h2>

							<div className="row">
								<div className="col-2">

									<img alt="Decorative checkmark" src={imageCheckmark} />

								</div>
								<div className="col">
									<h3>Have the tools to start their own business</h3>
									<p>They learn what it takes to get started, generate value, and make good decisions.</p>
								</div>
							</div>
							<div className="row gx-5">
								<div className="col-2">
									<img alt="Decorative checkmark" src={imageCheckmark} />
								</div>
								<div className="col">
									<h3>Are more employable and ready to work</h3>
									<p>They gain foundational business, financial, and technology literacies that are transferable to nearly every profession.</p>
								</div>
							</div>
							<div className="row gx-5">
								<div className="col-2">
									<img alt="Decorative checkmark" src={imageCheckmark}/>
								</div>
								<div className="col">
									<h3>Can earn industry-recognized certifications</h3>
									<p>They learn from a curriculum designed to help participants earn Pearson Certiport Entrepreneurship and Small Business Certification.</p>
								</div>
							</div>
						</div>

				</div>
				<hr/>
				<div className="row gx-5">
					<div className="col">
					<h4><strong>1: Introduction to Entrepreneurship</strong></h4>
						<p>Learn about and compare real entrepreneurs &amp; innovators; and learn how products &amp; services are developed in response to customer needs.</p>
						<h4><strong>2: Problem Solving &amp; Social Responsibility</strong></h4>
						<p>Identify ways to solve social problems; learn the purpose of mission and vision statements; and provide examples of socially responsible business practices.</p>
						<h4><strong>3: Business Ideas and Creative Thinking</strong></h4>
						<p>Learn about the entrepreneurial mindset; identify creative business ideation; engage in creative thinking; and learn the purpose of the business model canvas.</p>
						<h4><strong>4: Market Research</strong></h4>
						<p>Learn the importance of planning and using market research methodologies; the concept of Lean Startup Methodology; explore market research resources; understand primary and secondary data; refine business ideas based on research and applying research cycles.</p>
						<h4><strong>5: Economics</strong></h4>
						<p>Focusing on the U.S. economy, learn about types of economic systems; identify the role of the entrepreneur in the economy; learn about suppliers and consumers and their relationship; and learn the concept of supply and demand.</p>
					</div>
					<div className="col">
					<h4><strong>6: Competition</strong></h4>
						<p>Understand different types of competition and how it impacts pricing; identify different types of competitors; explore ways for businesses to remain competitive; and learn how to gather competitive intelligence and create competitive advantages.</p>
						<h4><strong>7: Value Propositions</strong></h4>
						<p>Identify and describe value proposition; differentiate between benefits and features; and identify ways to promote value.</p>
						<h4><strong>8: Business Expenses</strong></h4>
						<p>Examine common business expense categories; understand contribution margins; determine profit and loss; apply break-even analysis; and learn ways to manage ongoing business expenses.</p>
						<h4><strong>9: Revenue Streams</strong></h4>
						<p>Identify potential revenue streams; understand sales forecasting; and learn the parts and functions of income statements.</p>
						<h4><strong>10: Financing Your Business</strong></h4>
						<p>Estimate the start-up investment needed; learn about ROI and how to calculate it; and find ways to obtain investments.</p>
					</div>
					<div className="col">
					<h4><strong>11: Business Financials</strong></h4>
						<p>Learn how to utilize and analyze income statements; build a balance sheet; identify the functions of various financial statements; and learn how to calculate financial ratios.</p>
						<h4><strong>12: Managing Your Business</strong></h4>
						<p>Identify management functions and leaderships styles; create organizational structures; learn planning strategies; and learn how to create a healthy business environment.</p>
						<h4><strong>13: Growth and Exit Plans</strong></h4>
						<p>Understand business growth and product life cycles; learn how to value a business; identify possible exit strategies; and understand investments and how money grows.</p>
						<h4><strong>14: Legal Structures</strong></h4>
						<p>Identify different types of legal structures and their strengths and weaknesses.</p>
						<h4><strong>15: Constructing a Business Plan</strong></h4>
						<p>Apply what has been learned to create a business model canvas; create an elevator pitch; and learn how to build a pitch deck.</p>
					</div>
				</div>



			</div>
		</main>
	)
}
